<template>
  <div class="register-box">
    <van-nav-bar title="注册" left-arrow left-text="返回" @click-left="onClickLeft" />
    <div class="register-con">
      <van-form @submit="register">
        <div class="filed-box">
          <van-field
            v-model="userForm.userName"
            name="姓名"
            clearable
            required
            placeholder="输入姓名"
            maxlength="20"
            :rules="[{ required: true }]"
          />
        </div>
        <div class="filed-box">
          <van-field
            v-model="genderName"
            required
            clearable
            placeholder="请选择性别"
            readonly
            :rules="[{ required: true }]"
            @click="showPicker = true"
          >
            <template #button>
              <van-icon v-if="showPicker" name="arrow-up" />
              <van-icon v-else name="arrow-down" />
            </template>
          </van-field>
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              @cancel="showPicker = false"
              @confirm="onConfirm"
            >
              <template #option="item">
                <p style="text-align: center">{{ item.label }}</p>
              </template>
            </van-picker>
          </van-popup>
        </div>
        <div class="filed-box">
          <van-field
            v-if="!isWx"
            v-model="userForm.wechatId"
            name="微信号"
            clearable
            required
            maxlength="50"
            placeholder="输入微信号"
            :rules="[{ required: true }]"
          />
        </div>
        <div class="filed-box">
          <van-field
            v-model="userForm.password"
            type="password"
            name="密码"
            clearable
            required
            maxlength="16"
            placeholder="设置6~16位数密码,注意区分大小写"
            :rules="[
              { required: true },
              {
                validator: validatePwdLen,
                message: '长度在 6 到 16 个字符',
                trigger: 'onBlur',
              },
            ]"
          />
        </div>
        <div class="filed-box">
          <van-field
            v-model="userForm.repeatPassword"
            type="password"
            name="确认密码"
            clearable
            required
            maxlength="16"
            placeholder="再次输入密码"
            :rules="[
              { required: true },
              {
                validator: validatePwdLen,
                message: '长度在 6 到 16 个字符',
                trigger: 'onBlur',
              },
              {
                validator: validateRepeatPassword,
                message: '两次输入密码不一致',
                trigger: 'onBlur',
              },
            ]"
          />
        </div>
        <div class="filed-box">
          <van-field
            v-model="userForm.mobile"
            name="手机号"
            clearable
            :readonly="wxMobile"
            :disabled="wxMobile"
            required
            maxlength="11"
            type="number"
            placeholder="输入手机号"
            :rules="[
              { required: true },
              {
                validator: isPhoneNum,
                message: '请输入正确的手机号',
                trigger: 'onBlur',
              },
            ]"
          />
        </div>
        <div class="filed-box">
          <van-field
            v-model="userForm.smsCaptcha"
            name="短信验证码"
            clearable
            required
            placeholder="输入短信验证码"
            maxlength="6"
            :rules="[{ required: true }]"
          />
          <span :class="{ 'no-style': intervalId }" @click="sendPhoneVerifyRegist">{{ sendNotice }}</span>
        </div>
        <div class="filed-box">
          <van-field
            v-model="userForm.imageCaptcha"
            name="图形验证码"
            clearable
            required
            placeholder="输入图形验证码"
            :rules="[{ required: true }]"
          />
          <span>
            <img v-if="imgVisible" :src="imageCaptchaUrl" @click="init()" />
          </span>
        </div>
        <div class="btn-box">
          <van-button round block type="info">注册</van-button>
          <van-button
            round
            block
            @click.native.prevent="$router.push('/login')"
            style="margin-top: 10px"
          >返回登录</van-button>
        </div>
        <div class="radio-box">
          <van-radio-group v-model="accept">
            <van-radio name="1">
              我已阅读并同意
              <span style="color: #1989fa" @click="show = true">内推圈相关协议</span>
            </van-radio>
          </van-radio-group>
        </div>
      </van-form>
    </div>
    <van-dialog
      v-model="show"
      title="IT内推圈用户注册协议"
      :class="{'text-box':show}"
      @confirm="confirm"
      style="
        height: 70%;
        width: 80%;
        padding: 0px 16px;
      "
    >
      <div class="text-con">
        <p>
          《内推圈用户协议》(以下简称“本协议”)作为内推圈职位自助发布平台(以下简称“本网站”)提供服务的依据，确定用户在何种条件、以何种方式使用本网站及本网站的服务(具体载体包括但不限于网页、微信公众号平台、微信小程序等)。在使用本网站及相关服务前，您应当注册成为本网站用户。进行用户注册时，请您认真阅读本协议(尤其是字体加粗及/或下划线的内容)，一旦完成注册即表示您已经知悉并了解本协议，接受本协议的条款约束，本协议当即具有合同效力;
          如您对本协议的任何条款表示异议，您应当立即停止访问内推圈及使用相关服务。
        </p>
        <p>本协议包括基于本协议制定的各项规则，所有规则为本协议不可分割的一部分，与本协议具有同等效力。随着平台业务经营的发展及相关政策的变更，本网站用户协议将不时更新，我们会通过在网站公告、APP端推送、电子邮件等适当方式提醒您相关内容的更新。您也可以随时访问我们的用户协议页面(https://selfhelp.itneituiquan.com/privacy.html)来获知最新版本。当您继续使用本网站及相关服务，则视为您接受协议的变更，否则您应当停止访问网站及使用服务。</p>
        <p>有关用户个人信息安全及隐私保护的详细规定，适用《内推圈职位自助发布平台隐私政策》。</p>
        <br />
        <p>一、服务描述及定义</p>
        <br />
        <p>本网站是连接候选人与用人者的中介信息平台及职业社区。“本网站”“本平台”:指成都蓉易聘网络科技有限责任公司全权所有并运营的内推圈职位自助发布平台，载体包括但不限于网页(http://selfhelp.itneituiquan.com)、微信公众号、微信小程序等。“用户”:指具有完全民事行为能力的内推圈使用者。用户身份包括“用人者”等。“用人者”:指有用人需求，具有用人资格，在本网站上发布招聘及相关信息的企事业单位或其他组织。</p>
        <br />
        <p>二、成为注册用户</p>
        <br />
        <p>（一）用户主体适格</p>
        <p>符合下列条件之一且未曾被本网站封禁账户的自然人或者组织可以注册成为本网站用户，但本网站不保证一定能够通过注册。</p>
        <p>具备完全民事行为能力的自然人。</p>
        <p>依据中华人民共和国法律、行政法规、部门规章等规范性文件成立并合法存续的企业、机关、社团、事业单位和其他组织，具有能使用本网站服务的民事权利能力和民事行为能力并且具有合法用人资格(统称为“用人者”)。</p>
        <p>（二）用户信息完备</p>
        <p>用户需要提供明确的联系方式、通讯地址、注册者真实姓名或名称、资质文件(如企业营业执照、个人身份证)等信息方能完成注册或使用本网站的服务。为了合法、合规地为用户提供更好的服务，本网站将不时调整用户需提交的信息，。如用户不同意提供上述信息，则应当停止使用本网站的服务。用户需同意本网站对其注册信息资料进行审查，并就相关疑问或问题进行解答。本网站将在必要的限度内收集、使用这些信息，并会妥善保存、处理这些信息。</p>
        <p>（三）入驻平台审核</p>
        <p>用人者入驻本平台需经过本平台审核，有以下情形的不得入驻;如入驻之后出现此等情形的，平台有权对账户进行封禁，对于已支付的服务费用(如有),本网站有权不予退还:</p>
        <p>用人者违反法律、行政法规、部门规章、地方性法规等规范性文件，本网站的各项规则和协议以及社会公序良俗的，包括但不限于以下情形:</p>
        <p>
          涉嫌传销、赌博、色情、暴力、邪教与封建迷信、欺诈、非法集资等违法犯罪情形的;
          违法失信企业或法定代表人为失信被执行人的企业;
        </p>
        <p>被工商、公安、税务、工信、网信、人社、外管、商委、海关等行政机关查处或行政处罚，情节严重的;</p>
        <p>
          涉及其他违反法律、行政法规、部门规章等规范性文件的，以及违反社会公序良俗的。
          本网站有权要求用人者提供相应的资质文件(包括但不限于营业执照、经营许可证等)，并依据常识及社会大众通常认知水平做出是否合规的判断而不必须依据公权力机关事后通知、查处、判决等文件。对于涉及经营人力资源外包业务、劳务分包业务、猎头业务、招聘业务、职业教育培训业务等可能与本网站业务存在竞争关系或利益冲突的企业，本网站保留拒绝其注册、使用本网站的权利。
        </p>
        <br />
        <p>三、本网站的使用</p>
        <br />
        <p>用户依据协议及本网站发布的相关规则，招聘及职业问答等活动，享受本网站的用户福利、接受本网站的资讯信息及使用其他服务，具体形式包括但不限于发布招聘岗位等。用户与平台基于服务各自享有必要的权利，承担相应的义务。</p>
        <p>(一)用户承诺</p>
        <p>用户承诺使用“内推圈职位自助发布平台”仅用于用户自身招聘(含招聘宣传、雇主品牌建设)答等用途，不得偏离网站具体功能模块之目的。</p>
        <p>为了使用本网站的服务，用户应当提供真实、合法、准确的信息，包括但不限于姓名或名称、个人身份证明、资质证明、通讯地址、职位信息、公司介绍等(为了进行招聘，另一方用户通过本网站的特定功能可以查看此类信息)。用户承诺使用以上内容均出于自愿并已得到有效授权，且应如实描述和及时更新。</p>
        <p>用户应妥善设置及保管自己的账户和密码(例如设置较为复杂的密码，不将密码泄露给任何人)，该账户在本网站进行的所有操作和活动(包括但不限于信息浏览、发布招聘相关等)均视为用户自身真实的意思表示。尤其用人者应对其在本网站下账号及子账号的实际使用人(包括但不限于用人者员工)进行严格管理，并保证实际使用人严格遵守本网站的协议与规则，实际使用人在本网站上进行的任何行为均视为用人者行为，用人者就上述行为承担全部责任。</p>
        <p>用户承诺使用本网站服务过程中的任何行为以及发布的相关信息均应严格遵守法律、法规等规范性文件、本网站的各项规则和协议以及社会公序良俗;并确保不侵犯任何第三人的合法权益(包括但不限于知识产权、商业秘密等)，在使用本网站的服务过程中将最大程度的遵守诚实信用原则。</p>
        <p>
          如用户违反上述承诺，导致自身、平台或第三人遭受任何损失的，应由其自行承担所有责任。
          付费用户除应遵守本协议外，还应遵守付费服务对应的合同条款的约定。
        </p>
        <p>(二)用户行为限制</p>
        <p>用户的行为(包括但不限于使用本网站过程中的所有行为、针对本网站所进行的任何行为、利用本网站服务进行的后续行为等)不得违反法律、行政法规、部门规章、地方性法规等规范性文件，本网站的各项规则和协议以及社会公序良俗。本网站有权依据常识及社会大众通常认知水平做出是否合规的判断而不必须依据公权力机关事后通知、查处、判决等文件。</p>
        <p>用户不得在本网站或者利用本网站的服务制作、复制、发布、传播以下信息:反对宪法所确定的基本原则的;危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;损害国家荣誉和利益的;煽动民族仇恨、民族歧视、破坏民族团结的;破坏国家宗教政策，宣扬邪教和封建迷信的;散布谣言，扰乱社会秩序，破坏社会稳定的;散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的;侮辱或者诽谤他人，侵害他人合法权利的;涉及政治敏感内容的;含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、人身攻击、或其它有悖社会公序良俗令人反感的内容;对本网站可能造成严重负面影响的内容的;含有中国法律、法规、规章、条例以及任何具有法律效力的规范所限制或禁止的其它内容的;其他本网站认为不合适的内容。</p>
        <p>用户不得破坏网站运营秩序，不得从事与本网站服务无关的行为，不得发布除招聘信息、自身简历、职业问答所必需信息外的其他内容。</p>
        <p>用户不得以任何形式骚扰、侮辱、伤害其他用户。</p>
        <p>用户不得以虚假信息注册账号，不得冒用、盗用、借用他人、关联机构或社会名人的名义注册或使用账号，或以虚伪不实的方式陈述或谎称与任何人或机构有关。</p>
        <p>用户在未经本网站同意的情况下，不得转让或授权他人使用其所有的本网站账号。</p>
        <p>用户不得使用任何机器人软件、脚本等其他方式，自动访问或登录本网站。</p>
        <p>用户不得以任何手段或形式干扰本网站服务，包括但不限于利用技术手段恶意破坏网站、干扰或破坏与本网站相连线之服务器和网络、大量发布垃圾信息等。</p>
        <p>用户不得以任何方式对本网站(以及已有或可能发布的相关App、微信公众号、微信小程序等平台服务载体)进行反向工程;未经网站允许，不得采取任何爬虫、抓取、批量检索等手段对公开或非公开网站信息进行复制、收集。</p>
        <p>用户不得在未经本网站和招聘行为相对方的同意下，将本网站提供的服务和/或本网站的任何信息、资料以及在招聘找工作过程中任何信息、资料进行转让、出售、传播(包括但不限于以复制、修改、转发、收集、翻译等形式制作复制品、衍生品，或使其公布于众)或其他商业、非商业用途。</p>
        <p>如用户违反上述行为限制要求，本网站有权立即采取下列一个或多个措施:立即停止提供服务;对相关信息予以下线、删除或要求整改;封禁用户账号等。对于用户已支付的服务费用(如有)，本网站有权不予退还。</p>
        <p>(三)用人者的特别保证</p>
        <p>对于用人者及其关联单位(包括但不限于分公司、子公司、独立事业部等)入驻本网站并使用招聘相关服务事宜，用人者承诺并担保如下:用人者使用内推圈服务仅限用于自身员工招聘，承诺不利用服务从事其他事宜，包括但不限于为其他第三方代为招聘，收集求职者数据、简历用作他用，以自身招聘为名招收学员或办理贷款，使用平台服务从事公司业务经营活动，利用平台服务中聊天沟通等功能进行广告营销等情形。用人者成功入驻后，不得出现本协议“入驻平台审核”条款中所列举的禁止情形。用人者使用本网站服务，保证严格遵守平台用户协议、平台规则及与本网站签署的一切合同文件(如有)。在使用服务期间，保证不从事任何违反平台规则的业务、不发布任何违反平台规则的信息。</p>
        <p>如用人者违反上述承诺，本网站有权立即停止提供服务并对用人者在平台上的公司主页和/或发布的信息进行封禁，且对于用人者已支付的服务费用(如有)，本网站有权不予退还。</p>
        <p>(四)招聘行为</p>
        <p>用户(用人者)通过本平台审核，并依据法律法规及本协议第二条第(二)款规定提供招聘简章、营业执照或有关部门批准设立的文件、经办人身份证件、用人单位的委托证明等资料后，方可发布招聘信息、进行招聘活动。</p>
        <p>用户在本网站指定位置(包括本网站各级页面、为用人者生成的公司主页等)发布招聘相关信息，包括但不限于职位信息、公司简介(包含公司商标、名称、Logo、文字说明等)、配图等。上述行为均为用户自行操作。</p>
        <p>用人者发布的信息除用于招聘所需的必要信息外不得有其他内容，包括但不限于自身产品或服务的推广、销售等。</p>
        <p>本网站认为有必要的情况下，有权对用户发布的信息做出审查、指导，并有权要求用户做出解释、更正或说明。</p>
        <p>用户应当确保发布的招聘信息真实合法。用户招聘信息不实或违规的，用户自行承担相应的不利后果。因此给候选人、本网站或第三人造成损失的，还应承担相应的赔偿责任。涉及违法或构成刑事犯罪的，依据法律法规承担相应的责任。</p>
        <p>用户发布招聘信息或利用本网站提供的沟通工具与候选人进行沟通的，应严格遵守本用户协议第三条第(二)款项下的全部规则，另外还不得涉及以下内容:非互联网行业职位;涉及性别、地域等各类歧视;涉及薪酬面议、联系方式内容;涉及夸张性描述、夸大性承诺或虚假宣传;职位内容重复描述、无意义描述;职位要求与职位描述不一致;侮辱、诽谤、骚扰、诋毁、攻击第三人的;代招聘服务;招聘信息虚假;与招聘无关;违反社会公序良俗的其他情形。如用户违反上述要求，本网站有权立即采取下列一个或多个措施:立即停止提供服务;对相关信息予以下线、删除或要求整改;封禁用户账号等。</p>
        <p>(五)用户间保密</p>
        <p>用户对利用本网站服务进行找工作、招聘和职业问答活动，从而获取的其他用户的任何信息，负有保密的义务。保密的程度依据法律法规、用户间的合意以及一般人的合理注意义务决定。</p>
        <p>(六)用户间纠纷</p>
        <p>
          候选人与用人者之间的线下活动，包括沟通、面试、入职、建立劳动关系等，不受本网站的约束。双方发生纠纷的，依据法律法规、双方合同处理，本网站不负责处理。
          本网站提供用户反馈渠道，包括网站特定模块或功能，用户可按特定方式表达、反映用户间的纠纷，本网站将尽快处理这些问题，但所做出的答复可能不具有法律约束力，用户仍然可以按照法律法规规定将纠纷提交相关公权力机关。
        </p>
        <br />
        <p>四、责任声明</p>
        <br />
        <p>本网站作为信息服务与交流平台，所有职位信息、找工作信息及职业社区交流信息均由用户自行上传、发布。本网站对用户的真实资信状况、用户发布信息的真实性、合法性、准确性等不作保证。本网站不对简历的内容做实质性审查，不保证简历的真实、完整、准确;本网站不对用户实际经营状况做实质性审查，不对用户的资信状况做出保证。因上述原因给第三方造成的损失，本网站不承担责任。</p>
        <p>本网站致力于为用户提供优质、稳定的服务。但本网站不保证本网站系统:能够满足所有的用户需求;不出现任何的系统宕机、波动;不出现任何的数据丢失。因网络运营商原因、本网站进行网络调整、正常的系统维护升级等造成的本网站无法正常访问，本网站不承担任何责任。</p>
        <p>用户通过本网站获取的任何资料和信息，无论其来自于本网站或任何外部链接，本网站对其不做出任何保证。本网站提供与其它互联网上的网站或资源的链接，用户可能会因此跳转至其它运营商经营的网站，但不表示本网站与这些运营商有任何关联关系。其它运营商经营的网站均由各经营者自行负责，不属于本网站控制及负责范围之内。对于存在或来源于此类网站或资源的任何内容、广告、物品或其它资料，本网站亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、物品或服务所产生的任何损害或损失，本网站不负任何直接或间接的责任。涉及上述条款中的情形，而造成的用户利润、商誉等损失，本网站不承担任何直接、间接、附带、惩罚性的赔偿。</p>
        <p>本网站将不时地指定本网站(所有者)的关联公司或者第三方作为本网站的代理、基础服务商、技术支持方等，以便为用户提供本平台相应的服务。如用户需使用第三方服务以便使用本网站相应功能(例如使用第三方账号登录本网站、使用第三方支付工具购买本网站付费服务等)，还应相应遵守第三方服务提供商的相应规则。</p>
        <br />
        <p>五、陈述与保证</p>
        <br />
        <p>（一）知识产权</p>
        <p>
          本协议条款明确规定的，以及任何与本网站服务有关的内容的知识产权，由本网站或相关权利人所享有。该条款所指内容包括但不限于本网站设计、本网站代码、本网站内容、本网站资讯、用户信息等。该条款所指知识产权包括但不限
          于专利权、著作权、商标权等。
          本网站将通过技术手段对用户数据进行去标识化处理(去标识化处理的信息将无法识别主体)，在此情况下本网站有权使用已经去标识化的信息。在个人信息进行脱敏处理的前提下，对用户数据进行分析并予以利用所形成的所有智力成果均归属于本网站所有(包括但不限于专利权、著作权;例如基于平台用户去标识化信息对互联网行业及招聘领域的分析报告等)。
        </p>
        <p>（二）保密义务</p>
        <p>用户保证在使用本网站过程中所获悉的属于本网站及他方的且无法自公开渠道获得的文件及资料(包括但不限于个人信息、商业秘密、公司计划、运营活动、财务信息、技术信息、经营信息及其他商业秘密)属于保密信息。对于此等信息，本网站对用户负有保密义务，用户之间也负有保密义务。未经该资料和文件的原提供方同意，任何一方不得向第三方泄露该保密信息的全部或者部分内容。但法律、行政法规、部门规章等规范性文件另有规定或者双方另有约定的除外。</p>
        <br />
        <p>六、协议解除、终止</p>
        <br />
        <p>有以下情形的，本协议归于解除或终止:</p>
        <p>本网站基于法律法规等规范性文件、本网站的各项规则和协议以及社会公序良俗，终止对用户提供服务的。该情形的协议终止并不免除用户应承担的相应责任。</p>
        <p>一方主体归于消灭、撤销等无法有效存续状态的。依据法律法规的规定处理未决问题。</p>
        <p>因不可抗力使本协议无法继续履行或者没有履行必要和意义。不可抗力是指不能遇见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。</p>
        <p>本协议终止或更新时，用户不接受新的协议。用户应当立即停止使用本网站的服务。</p>
        <br />
        <p>七、违约责任</p>
        <br />
        <p>任何一方违反本协议约定的行为均构成违约行为，均应承担相应的责任。</p>
        <p>对于用户的任何违反本协议规定的行为，本网站有权采取相应处理措施，方式包括但不限于记录不良行为、调整用户信用等级、黑白名单限制、暂停服务、下线信息、封禁用户账号等。形式包括系统后台记录或网站公示公开。该等处理措施将影响用户使用本网站的服务，涉及违法、犯罪的，本网站将移交司法机关处理。</p>
        <p>遭受不可抗力事件的一方可暂行中止履行本合同项下的义务直至不可抗力事件的影响消除为止，并且无需为此而承担违约责任;但应尽最大努力克服该事件，减轻其负面影响。</p>
        <!-- <div class="btn-box">
          <van-button round block type="info" @click="goAgree"
            >同 意 并 继 续</van-button
          >
        </div>-->
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { isPhoneNum } from '../../utils/tool';
export default {
  name: 'Register',
  data() {
    return {
      show: false,
      showPicker: false,
      genderName: '',
      userForm: {
        userName: null, //姓名
        gender: null, //性别
        wechatId: null, //微信号
        password: null, //密码
        repeatPassword: null, //确认密码
        mobile: null, //手机号
        captcha: null, //图形验证码
        captchaHashing: null, //图形验证码hash值(从cookie中取)
        smsCaptcha: null, //短信验证码
        // email:"88955661@qq.com", //邮箱
      },
      intervalId: null,
      sendNotice: '发送验证码',
      smsTemplateType: 'REGIST', //验证类型("REGIST"|"LOGIN")
      imageCaptchaUrl: null,
      imgVisible: true,
      columns: [
        { label: '男', value: 'MALE' },
        { label: '女', value: 'FEMALE' },
      ],
      accept: '',
      isWx: false,
      isPhoneNum: isPhoneNum,
      wxMobile: false, // 微信手机号是不是已在
    };
  },
  created() {
    this.init();
    this.isWx = /MicroMessenger/i.test(window.navigator.userAgent);
  },
  mounted() {
    localStorage.setItem('userInfo', '');
    localStorage.setItem('accessToken', '');
    let { query } = this.$route;
    if (query.mobile) {
      this.wxMobile = true;
      this.userForm.mobile = query.mobile;
    }
  },
  methods: {
    async init() {
      // 绑定hash
      this.bindCaptchaHashing();
      // 刷新验证码图片
      this.refreshImageCaptcha();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 密码长度
    validatePwdLen(value) {
      if (value.length < 6 || value.length > 16) {
        return false;
      } else {
        return true;
      }
    },
    // 确认密码校验
    validateRepeatPassword(value) {
      if (value !== this.userForm.password) {
        return false;
      } else {
        return true;
      }
    },
    // 刷新验证码
    async refreshImageCaptcha() {
      this.imgVisible = false;
      await this.$nextTick(() => {
        this.imgVisible = true;
      });
    },
    //将captchaHashing绑定到form上.
    async bindCaptchaHashing() {
      var uuid = Math.random();
      this.imageCaptchaUrl = `${process.env.VUE_APP_BASE_URL}/selfhelpOfficialAccounts/captcha.png?id=${uuid}`;
      var captchaHashing = uuid;
      this.userForm.captchaHashing = captchaHashing;
    },
    onConfirm(item) {
      this.genderName = item.label;
      this.userForm.gender = item.value;
      this.showPicker = false;
    },

    //发送手机验证码
    sendPhoneVerifyRegist() {
      // 未到60s
      if (this.intervalId) {
        return;
      }
      // 校验手机号是否正确.
      if (!isPhoneNum(this.userForm.mobile)) {
        this.$toast('请输入正确的手机号!');
        return;
      }
      // 发送请求,获得手机验证码
      this.$http
        .post('/selfhelpOfficialAccounts/message/sms/send', {
          mobile: this.userForm.mobile,
          smsTemplateType: this.smsTemplateType,
        })
        .then((res) => {
          if (res.code === 200) {
            this.downTime();
            this.$toast('验证短信发送成功!');
          } else {
            this.$toast(res.message || '发送失败!');
          }
        });
    },
    // 倒记时60秒
    downTime() {
      if (this.intervalId != null) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      var time = 60;
      this.intervalId = setInterval(() => {
        time--;
        this.sendNotice = `再次发送(${time}s)`;
        if (time <= 0) {
          clearInterval(this.intervalId);
          this.intervalId = null;
          this.sendNotice = `发送验证码`;
        }
      }, 1000);
    },
    register() {
      if (!this.accept) return this.$toast('请阅读并同意《内推圈注册协议》!');
      this.$http
        .post('/selfhelpOfficialAccounts/users/register', this.userForm)
        .then((res) => {
          if (res.code === 200) {
            this.$toast('注册成功!请重新登录!', 2);
            localStorage.setItem('userInfo', '');
            localStorage.setItem('accessToken', '');
            this.$router.push('/login');
          } else {
            this.$toast(res.message || '注册失败!');
          }
        });
    },
    confirm() {
      this.accept = '1';
    },
  },
};
</script>

<style lang="scss" scoped>
.register-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .register-con {
    flex: 1;
    overflow-y: auto;
    .icon-box {
      margin-top: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 25px;
      }
      img:first-child {
        width: 90px;
      }
      img:last-child {
        width: 70px;
      }
      span {
        width: 1px;
        height: 20px;
        background: #e5e5e5;
        margin: 0 10px;
      }
    }
    .van-cell {
      padding: 10px 16px;
      flex: 1;
      border-radius: 32px;
      background: #f7f7f7;
    }
    .van-cell::after {
      border-bottom: none;
    }
    .van-cell--required::before {
      display: none;
    }
    .filed-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 16px;
      background: #f7f7f7;
      border-radius: 32px;
      img {
        width: 77px;
        height: 23px;
      }
      span {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #206cfe;
        margin: 0 16px;
        text-align: left;
      }
      .no-style {
        color: #cccccc;
      }
    }
    .btn-box {
      margin-top: 40px;
      padding: 0 20px;
    }
    .radio-box {
      margin: 20px;
      ::v-deep .van-radio__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .wx-register {
      margin-top: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 47px;
        width: 47px;
        margin-bottom: 10px;
      }
    }
  }
  .text-box {
    height: 70%;
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
  }
  ::v-deep .van-dialog__content {
    flex: 1;
    overflow-y: auto;
    p {
      word-break: break-all;
    }
  }
}
</style>
